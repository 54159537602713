/**
 * @flow
 * @relayHash bfa2c5ac25bbf48e20b1535e6326bff8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MeComponent_user$ref = any;
export type MeQueryVariables = {||};
export type MeQueryResponse = {|
  +me: ?{|
    +$fragmentRefs: MeComponent_user$ref
  |}
|};
export type MeQuery = {|
  variables: MeQueryVariables,
  response: MeQueryResponse,
|};
*/


/*
query MeQuery {
  me {
    ...MeComponent_user
    id
  }
}

fragment MeComponent_user on Me {
  name
  ...UserProfile_user
  ...UserSecurity_user
  ...UserPrivacy_user
}

fragment UserProfile_user on Me {
  name
  ...UserInfoEdit_user
}

fragment UserSecurity_user on Me {
  id
  dbid
  get_send_successful_login_notifications
  get_send_failed_login_notifications
  two_factor
}

fragment UserPrivacy_user on Me {
  dbid
  providers
}

fragment UserInfoEdit_user on Me {
  id
  dbid
  name
  email
  unconfirmed_email
  get_send_email_notifications
  source {
    id
    dbid
    created_at
    image
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MeQuery",
  "id": null,
  "text": "query MeQuery {\n  me {\n    ...MeComponent_user\n    id\n  }\n}\n\nfragment MeComponent_user on Me {\n  name\n  ...UserProfile_user\n  ...UserSecurity_user\n  ...UserPrivacy_user\n}\n\nfragment UserProfile_user on Me {\n  name\n  ...UserInfoEdit_user\n}\n\nfragment UserSecurity_user on Me {\n  id\n  dbid\n  get_send_successful_login_notifications\n  get_send_failed_login_notifications\n  two_factor\n}\n\nfragment UserPrivacy_user on Me {\n  dbid\n  providers\n}\n\nfragment UserInfoEdit_user on Me {\n  id\n  dbid\n  name\n  email\n  unconfirmed_email\n  get_send_email_notifications\n  source {\n    id\n    dbid\n    created_at\n    image\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MeQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "MeComponent_user",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MeQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          v0,
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "unconfirmed_email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_send_email_notifications",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "source",
            "storageKey": null,
            "args": null,
            "concreteType": "Source",
            "plural": false,
            "selections": [
              v0,
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "created_at",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "image",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_send_successful_login_notifications",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_send_failed_login_notifications",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "two_factor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "providers",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f47f047d67587972bfaab3c4b7f9149';
module.exports = node;
