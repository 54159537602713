/**
 * @flow
 * @relayHash f60033d5410f437bcb71f5ae5a0ace7a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTeamInput = {
  id?: ?string,
  archived?: ?number,
  private?: ?boolean,
  description?: ?string,
  name?: ?string,
  add_auto_task?: ?any,
  media_verification_statuses?: ?any,
  set_team_tasks?: ?any,
  rules?: ?string,
  remove_auto_task?: ?string,
  empty_trash?: ?number,
  report?: ?any,
  slack_notifications_enabled?: ?string,
  slack_webhook?: ?string,
  slack_notifications?: ?string,
  language?: ?string,
  languages?: ?any,
  language_detection?: ?boolean,
  tipline_inbox_filters?: ?string,
  suggested_matches_filters?: ?string,
  outgoing_urls_utm_code?: ?string,
  shorten_outgoing_urls?: ?boolean,
  clientMutationId?: ?string,
};
export type BotPreviewUpdateTeamLinkManagementMutationVariables = {|
  input: UpdateTeamInput
|};
export type BotPreviewUpdateTeamLinkManagementMutationResponse = {|
  +updateTeam: ?{|
    +team: ?{|
      +get_shorten_outgoing_urls: ?boolean,
      +get_outgoing_urls_utm_code: ?string,
    |}
  |}
|};
export type BotPreviewUpdateTeamLinkManagementMutation = {|
  variables: BotPreviewUpdateTeamLinkManagementMutationVariables,
  response: BotPreviewUpdateTeamLinkManagementMutationResponse,
|};
*/


/*
mutation BotPreviewUpdateTeamLinkManagementMutation(
  $input: UpdateTeamInput!
) {
  updateTeam(input: $input) {
    team {
      get_shorten_outgoing_urls
      get_outgoing_urls_utm_code
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTeamInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateTeamInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "get_shorten_outgoing_urls",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "get_outgoing_urls_utm_code",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "BotPreviewUpdateTeamLinkManagementMutation",
  "id": null,
  "text": "mutation BotPreviewUpdateTeamLinkManagementMutation(\n  $input: UpdateTeamInput!\n) {\n  updateTeam(input: $input) {\n    team {\n      get_shorten_outgoing_urls\n      get_outgoing_urls_utm_code\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BotPreviewUpdateTeamLinkManagementMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateTeam",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateTeamPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v2,
              v3
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BotPreviewUpdateTeamLinkManagementMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateTeam",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateTeamPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v2,
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1d07897f87cc26cbe14f646f4f2313cc';
module.exports = node;
