/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MediaFeedInformation_projectMedia$ref = any;
type MediaOriginBanner_projectMedia$ref = any;
type MediaOrigin_projectMedia$ref = any;
type SmallMediaCard_media$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaRelationship_relationship$ref: FragmentReference;
export type MediaRelationship_relationship = {|
  +source_id: ?number,
  +target_id: ?number,
  +id: string,
  +target: ?{|
    +id: string,
    +media_slug: ?string,
    +title: ?string,
    +description: ?string,
    +type: ?string,
    +last_seen: ?string,
    +created_at: ?string,
    +show_warning_cover: ?boolean,
    +quote: ?string,
    +imported_from_feed_id: ?number,
    +requests_count: ?number,
    +media_cluster_origin_user: ?{|
      +name: ?string
    |},
    +media: ?{|
      +$fragmentRefs: SmallMediaCard_media$ref
    |},
    +$fragmentRefs: MediaFeedInformation_projectMedia$ref & MediaOrigin_projectMedia$ref & MediaOriginBanner_projectMedia$ref,
  |},
  +$refType: MediaRelationship_relationship$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MediaRelationship_relationship",
  "type": "Relationship",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "source_id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "target_id",
      "args": null,
      "storageKey": null
    },
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "target",
      "storageKey": null,
      "args": null,
      "concreteType": "ProjectMedia",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "media_slug",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "title",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "last_seen",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "created_at",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "show_warning_cover",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "quote",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "imported_from_feed_id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "requests_count",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "media_cluster_origin_user",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "media",
          "storageKey": null,
          "args": null,
          "concreteType": "Media",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "SmallMediaCard_media",
              "args": null
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "MediaFeedInformation_projectMedia",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "MediaOrigin_projectMedia",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "MediaOriginBanner_projectMedia",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '50904e63f5f406a2e6038fae0ece7282';
module.exports = node;
