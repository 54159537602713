/**
 * @flow
 * @relayHash 62bdeaa2156a753647139206f1b354fd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type LinkManagement_team$ref = any;
export type BotPreviewQueryRendererQueryVariables = {|
  teamSlug: string,
  random?: ?string,
|};
export type BotPreviewQueryRendererQueryResponse = {|
  +me: ?{|
    +email: ?string,
    +is_admin: ?boolean,
    +token: ?string,
  |},
  +team: ?{|
    +id: string,
    +avatar: ?string,
    +get_language_detection: ?boolean,
    +get_shorten_outgoing_urls: ?boolean,
    +get_outgoing_urls_utm_code: ?string,
    +smooch_bot: ?{|
      +id: string,
      +json_settings: ?string,
      +lock_version: ?number,
      +smooch_enabled_integrations: ?any,
    |},
    +alegre_bot: ?{|
      +id: string,
      +alegre_settings: ?any,
      +lock_version: ?number,
    |},
    +$fragmentRefs: LinkManagement_team$ref,
  |},
|};
export type BotPreviewQueryRendererQuery = {|
  variables: BotPreviewQueryRendererQueryVariables,
  response: BotPreviewQueryRendererQueryResponse,
|};
*/


/*
query BotPreviewQueryRendererQuery(
  $teamSlug: String!
  $random: String
) {
  me {
    email
    is_admin
    token
    id
  }
  team(slug: $teamSlug, random: $random) {
    id
    avatar
    get_language_detection
    get_shorten_outgoing_urls
    get_outgoing_urls_utm_code
    smooch_bot: team_bot_installation(bot_identifier: "smooch") {
      id
      json_settings
      lock_version
      smooch_enabled_integrations(force: true)
    }
    alegre_bot: team_bot_installation(bot_identifier: "alegre") {
      id
      alegre_settings
      lock_version
    }
    ...LinkManagement_team
  }
}

fragment LinkManagement_team on Team {
  tipline_newsletters(first: 10000) {
    edges {
      node {
        content_type
        enabled
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "random",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "is_admin",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "token",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "random",
    "variableName": "random",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avatar",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "get_language_detection",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "get_shorten_outgoing_urls",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "get_outgoing_urls_utm_code",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lock_version",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "LinkedField",
  "alias": "smooch_bot",
  "name": "team_bot_installation",
  "storageKey": "team_bot_installation(bot_identifier:\"smooch\")",
  "args": [
    {
      "kind": "Literal",
      "name": "bot_identifier",
      "value": "smooch",
      "type": "String!"
    }
  ],
  "concreteType": "TeamBotInstallation",
  "plural": false,
  "selections": [
    v5,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "json_settings",
      "args": null,
      "storageKey": null
    },
    v10,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "smooch_enabled_integrations",
      "args": [
        {
          "kind": "Literal",
          "name": "force",
          "value": true,
          "type": "Boolean"
        }
      ],
      "storageKey": "smooch_enabled_integrations(force:true)"
    }
  ]
},
v12 = {
  "kind": "LinkedField",
  "alias": "alegre_bot",
  "name": "team_bot_installation",
  "storageKey": "team_bot_installation(bot_identifier:\"alegre\")",
  "args": [
    {
      "kind": "Literal",
      "name": "bot_identifier",
      "value": "alegre",
      "type": "String!"
    }
  ],
  "concreteType": "TeamBotInstallation",
  "plural": false,
  "selections": [
    v5,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "alegre_settings",
      "args": null,
      "storageKey": null
    },
    v10
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "BotPreviewQueryRendererQuery",
  "id": null,
  "text": "query BotPreviewQueryRendererQuery(\n  $teamSlug: String!\n  $random: String\n) {\n  me {\n    email\n    is_admin\n    token\n    id\n  }\n  team(slug: $teamSlug, random: $random) {\n    id\n    avatar\n    get_language_detection\n    get_shorten_outgoing_urls\n    get_outgoing_urls_utm_code\n    smooch_bot: team_bot_installation(bot_identifier: \"smooch\") {\n      id\n      json_settings\n      lock_version\n      smooch_enabled_integrations(force: true)\n    }\n    alegre_bot: team_bot_installation(bot_identifier: \"alegre\") {\n      id\n      alegre_settings\n      lock_version\n    }\n    ...LinkManagement_team\n  }\n}\n\nfragment LinkManagement_team on Team {\n  tipline_newsletters(first: 10000) {\n    edges {\n      node {\n        content_type\n        enabled\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BotPreviewQueryRendererQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          v1,
          v2,
          v3
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v4,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v5,
          v6,
          v7,
          v8,
          v9,
          v11,
          v12,
          {
            "kind": "FragmentSpread",
            "name": "LinkManagement_team",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BotPreviewQueryRendererQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          v1,
          v2,
          v3,
          v5
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v4,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v5,
          v6,
          v7,
          v8,
          v9,
          v11,
          v12,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tipline_newsletters",
            "storageKey": "tipline_newsletters(first:10000)",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 10000,
                "type": "Int"
              }
            ],
            "concreteType": "TiplineNewsletterConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TiplineNewsletterEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TiplineNewsletter",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "content_type",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "enabled",
                        "args": null,
                        "storageKey": null
                      },
                      v5
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '31ce0bf141d41b5057d460335707cd69';
module.exports = node;
