/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type ReportDesignerForm_media$ref = any;
type ReportDesignerForm_team$ref = any;
type ReportDesignerPreview_media$ref = any;
type ReportDesignerTopBar_media$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ReportDesignerComponent_media$ref: FragmentReference;
export type ReportDesignerComponent_media = {|
  +id: string,
  +dbid: ?number,
  +archived: ?number,
  +permissions: ?string,
  +last_status: ?string,
  +dynamic_annotation_report_design: ?{|
    +id: string,
    +dbid: ?string,
    +data: ?any,
  |},
  +team: ?{|
    +slug: string,
    +get_language: ?string,
    +get_languages: ?string,
    +verification_statuses: ?any,
    +$fragmentRefs: ReportDesignerForm_team$ref,
  |},
  +$fragmentRefs: ReportDesignerTopBar_media$ref & ReportDesignerPreview_media$ref & ReportDesignerForm_media$ref,
  +$refType: ReportDesignerComponent_media$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ReportDesignerComponent_media",
  "type": "ProjectMedia",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "archived",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "permissions",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "last_status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "dynamic_annotation_report_design",
      "storageKey": null,
      "args": null,
      "concreteType": "Dynamic",
      "plural": false,
      "selections": [
        v0,
        v1,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "data",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "Team",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "slug",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "get_language",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "get_languages",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "verification_statuses",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "ReportDesignerForm_team",
          "args": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "ReportDesignerTopBar_media",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ReportDesignerPreview_media",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "ReportDesignerForm_media",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '3386ee35b1a43d31526261bf70224d52';
module.exports = node;
