/**
 * @flow
 * @relayHash 4f484a8f6d5cccfe48d594add37d262a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SimilarityComponent_team$ref = any;
export type SimilarityQueryVariables = {|
  teamSlug: string
|};
export type SimilarityQueryResponse = {|
  +team: ?{|
    +id: string,
    +$fragmentRefs: SimilarityComponent_team$ref,
  |},
  +me: ?{|
    +is_admin: ?boolean
  |},
|};
export type SimilarityQuery = {|
  variables: SimilarityQueryVariables,
  response: SimilarityQueryResponse,
|};
*/


/*
query SimilarityQuery(
  $teamSlug: String!
) {
  team(slug: $teamSlug) {
    id
    ...SimilarityComponent_team
  }
  me {
    is_admin
    id
  }
}

fragment SimilarityComponent_team on Team {
  id
  permissions
  alegre_bot: team_bot_installation(bot_identifier: "alegre") {
    id
    lock_version
    alegre_settings
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "is_admin",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SimilarityQuery",
  "id": null,
  "text": "query SimilarityQuery(\n  $teamSlug: String!\n) {\n  team(slug: $teamSlug) {\n    id\n    ...SimilarityComponent_team\n  }\n  me {\n    is_admin\n    id\n  }\n}\n\nfragment SimilarityComponent_team on Team {\n  id\n  permissions\n  alegre_bot: team_bot_installation(bot_identifier: \"alegre\") {\n    id\n    lock_version\n    alegre_settings\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SimilarityQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "FragmentSpread",
            "name": "SimilarityComponent_team",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          v3
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SimilarityQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "permissions",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": "alegre_bot",
            "name": "team_bot_installation",
            "storageKey": "team_bot_installation(bot_identifier:\"alegre\")",
            "args": [
              {
                "kind": "Literal",
                "name": "bot_identifier",
                "value": "alegre",
                "type": "String!"
              }
            ],
            "concreteType": "TeamBotInstallation",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lock_version",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "alegre_settings",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          v3,
          v2
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6fe6d87bef972554c05112e15b484841';
module.exports = node;
