/**
 * @flow
 * @relayHash 0032c12ef6be3ac819d06f8d17f51736
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateClaimDescriptionInput = {
  description?: ?string,
  context?: ?string,
  project_media_id?: ?number,
  enable_create_blank_media?: ?boolean,
  clientMutationId?: ?string,
};
export type ClaimFactCheckFormCreateClaimDescriptionMutationVariables = {|
  input: CreateClaimDescriptionInput
|};
export type ClaimFactCheckFormCreateClaimDescriptionMutationResponse = {|
  +createClaimDescription: ?{|
    +claim_description: ?{|
      +id: string,
      +dbid: ?number,
      +updated_at: ?string,
      +description: ?string,
      +context: ?string,
      +user: ?{|
        +name: ?string
      |},
    |},
    +project_media: ?{|
      +articles_count: ?number,
      +fact_check: ?{|
        +id: string,
        +title: ?string,
        +summary: ?string,
        +url: ?string,
        +language: ?string,
        +rating: ?string,
        +tags: ?$ReadOnlyArray<?string>,
        +updated_at: ?string,
        +user: ?{|
          +name: ?string
        |},
      |},
    |},
  |}
|};
export type ClaimFactCheckFormCreateClaimDescriptionMutation = {|
  variables: ClaimFactCheckFormCreateClaimDescriptionMutationVariables,
  response: ClaimFactCheckFormCreateClaimDescriptionMutationResponse,
|};
*/


/*
mutation ClaimFactCheckFormCreateClaimDescriptionMutation(
  $input: CreateClaimDescriptionInput!
) {
  createClaimDescription(input: $input) {
    claim_description {
      id
      dbid
      updated_at
      description
      context
      user {
        name
        id
      }
    }
    project_media {
      articles_count
      fact_check {
        id
        title
        summary
        url
        language
        rating
        tags
        updated_at
        user {
          name
          id
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateClaimDescriptionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateClaimDescriptionInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updated_at",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "context",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    v7
  ]
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "articles_count",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "summary",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "language",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rating",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tags",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    v7,
    v2
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ClaimFactCheckFormCreateClaimDescriptionMutation",
  "id": null,
  "text": "mutation ClaimFactCheckFormCreateClaimDescriptionMutation(\n  $input: CreateClaimDescriptionInput!\n) {\n  createClaimDescription(input: $input) {\n    claim_description {\n      id\n      dbid\n      updated_at\n      description\n      context\n      user {\n        name\n        id\n      }\n    }\n    project_media {\n      articles_count\n      fact_check {\n        id\n        title\n        summary\n        url\n        language\n        rating\n        tags\n        updated_at\n        user {\n          name\n          id\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ClaimFactCheckFormCreateClaimDescriptionMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClaimDescription",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateClaimDescriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "claim_description",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimDescription",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v8
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v9,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "fact_check",
                "storageKey": null,
                "args": null,
                "concreteType": "FactCheck",
                "plural": false,
                "selections": [
                  v2,
                  v10,
                  v11,
                  v12,
                  v13,
                  v14,
                  v15,
                  v4,
                  v8
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ClaimFactCheckFormCreateClaimDescriptionMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createClaimDescription",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateClaimDescriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "claim_description",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimDescription",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v16
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "project_media",
            "storageKey": null,
            "args": null,
            "concreteType": "ProjectMedia",
            "plural": false,
            "selections": [
              v9,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "fact_check",
                "storageKey": null,
                "args": null,
                "concreteType": "FactCheck",
                "plural": false,
                "selections": [
                  v2,
                  v10,
                  v11,
                  v12,
                  v13,
                  v14,
                  v15,
                  v4,
                  v16
                ]
              },
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bee456bb1637eea94d6c474a94fceee1';
module.exports = node;
