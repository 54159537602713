/**
 * @flow
 * @relayHash cb4cf2f74baa7b365482fa722af3f5df
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateClaimDescriptionInput = {
  id?: ?string,
  description?: ?string,
  context?: ?string,
  project_media_id?: ?number,
  enable_create_blank_media?: ?boolean,
  clientMutationId?: ?string,
};
export type ClaimFactCheckFormUpdateClaimDescriptionMutationVariables = {|
  input: UpdateClaimDescriptionInput
|};
export type ClaimFactCheckFormUpdateClaimDescriptionMutationResponse = {|
  +updateClaimDescription: ?{|
    +claim_description: ?{|
      +id: string,
      +dbid: ?number,
      +updated_at: ?string,
      +context: ?string,
      +description: ?string,
      +user: ?{|
        +name: ?string
      |},
      +project_media: ?{|
        +title: ?string,
        +title_field: ?string,
        +custom_title: ?string,
        +report_status: ?string,
        +status: ?string,
        +last_status: ?string,
        +last_status_obj: ?{|
          +id: string,
          +locked: ?boolean,
        |},
      |},
    |}
  |}
|};
export type ClaimFactCheckFormUpdateClaimDescriptionMutation = {|
  variables: ClaimFactCheckFormUpdateClaimDescriptionMutationVariables,
  response: ClaimFactCheckFormUpdateClaimDescriptionMutationResponse,
|};
*/


/*
mutation ClaimFactCheckFormUpdateClaimDescriptionMutation(
  $input: UpdateClaimDescriptionInput!
) {
  updateClaimDescription(input: $input) {
    claim_description {
      id
      dbid
      updated_at
      context
      description
      user {
        name
        id
      }
      project_media {
        title
        title_field
        custom_title
        report_status
        status
        last_status
        last_status_obj {
          id
          locked
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateClaimDescriptionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateClaimDescriptionInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updated_at",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "context",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title_field",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "custom_title",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "report_status",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "last_status",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "last_status_obj",
  "storageKey": null,
  "args": null,
  "concreteType": "Dynamic",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "locked",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ClaimFactCheckFormUpdateClaimDescriptionMutation",
  "id": null,
  "text": "mutation ClaimFactCheckFormUpdateClaimDescriptionMutation(\n  $input: UpdateClaimDescriptionInput!\n) {\n  updateClaimDescription(input: $input) {\n    claim_description {\n      id\n      dbid\n      updated_at\n      context\n      description\n      user {\n        name\n        id\n      }\n      project_media {\n        title\n        title_field\n        custom_title\n        report_status\n        status\n        last_status\n        last_status_obj {\n          id\n          locked\n        }\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ClaimFactCheckFormUpdateClaimDescriptionMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateClaimDescription",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateClaimDescriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "claim_description",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimDescription",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  v7
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "project_media",
                "storageKey": null,
                "args": null,
                "concreteType": "ProjectMedia",
                "plural": false,
                "selections": [
                  v8,
                  v9,
                  v10,
                  v11,
                  v12,
                  v13,
                  v14
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ClaimFactCheckFormUpdateClaimDescriptionMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateClaimDescription",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateClaimDescriptionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "claim_description",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimDescription",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  v7,
                  v2
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "project_media",
                "storageKey": null,
                "args": null,
                "concreteType": "ProjectMedia",
                "plural": false,
                "selections": [
                  v8,
                  v9,
                  v10,
                  v11,
                  v12,
                  v13,
                  v14,
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '32f75b9e7484d6177b51039fa15e7840';
module.exports = node;
