/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TeamReportComponent_team$ref: FragmentReference;
export type TeamReportComponent_team = {|
  +id: string,
  +permissions: ?string,
  +get_language: ?string,
  +get_languages: ?string,
  +get_report: ?any,
  +smooch_bot: ?{|
    +id: string
  |},
  +$refType: TeamReportComponent_team$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "TeamReportComponent_team",
  "type": "Team",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "permissions",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "get_language",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "get_languages",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "get_report",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "smooch_bot",
      "name": "team_bot_installation",
      "storageKey": "team_bot_installation(bot_identifier:\"smooch\")",
      "args": [
        {
          "kind": "Literal",
          "name": "bot_identifier",
          "value": "smooch",
          "type": "String!"
        }
      ],
      "concreteType": "TeamBotInstallation",
      "plural": false,
      "selections": [
        v0
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '812b8302666c23e3648fb281276c0f1d';
module.exports = node;
