/**
 * @flow
 * @relayHash b7b68e5d4267aa857c785d63eb52a57b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ClaimFactCheckForm_article$ref = any;
type ClaimFactCheckForm_team$ref = any;
export type ClaimFactCheckFormQueryVariables = {|
  teamSlug: string,
  factCheckId: string,
|};
export type ClaimFactCheckFormQueryResponse = {|
  +team: ?{|
    +$fragmentRefs: ClaimFactCheckForm_team$ref
  |},
  +fact_check: ?{|
    +$fragmentRefs: ClaimFactCheckForm_article$ref
  |},
|};
export type ClaimFactCheckFormQuery = {|
  variables: ClaimFactCheckFormQueryVariables,
  response: ClaimFactCheckFormQueryResponse,
|};
*/


/*
query ClaimFactCheckFormQuery(
  $teamSlug: String!
  $factCheckId: ID!
) {
  team(slug: $teamSlug) {
    ...ClaimFactCheckForm_team
    id
  }
  fact_check(id: $factCheckId) {
    ...ClaimFactCheckForm_article
    id
  }
}

fragment ClaimFactCheckForm_team on Team {
  id
  get_language
  ...ArticleForm_team
}

fragment ClaimFactCheckForm_article on FactCheck {
  id
  claim_description {
    id
  }
  ...ArticleForm_article
}

fragment ArticleForm_article on Node {
  ... on FactCheck {
    title
    summary
    url
    language
    tags
    rating
    report_status
    author {
      name
      id
    }
    updated_at
    created_at
    user {
      name
      id
    }
    claim_description {
      description
      context
      project_media {
        dbid
        title
        type
        full_url
        last_status_obj {
          locked
          id
        }
        id
      }
      id
    }
  }
  ... on Explainer {
    title
    description
    url
    language
    tags
    author {
      name
      id
    }
    updated_at
    created_at
    user {
      name
      id
    }
  }
  ...ArticleTrash_article
}

fragment ArticleTrash_article on Node {
  ... on FactCheck {
    id
    trashed
    claim_description {
      project_media {
        title
        full_url
        type
        id
      }
      id
    }
  }
  ... on Explainer {
    id
    trashed
  }
}

fragment ArticleForm_team on Team {
  verification_statuses
  get_language
  get_languages
  slug
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "factCheckId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "factCheckId",
    "type": "ID!"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  },
  v3
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ClaimFactCheckFormQuery",
  "id": null,
  "text": "query ClaimFactCheckFormQuery(\n  $teamSlug: String!\n  $factCheckId: ID!\n) {\n  team(slug: $teamSlug) {\n    ...ClaimFactCheckForm_team\n    id\n  }\n  fact_check(id: $factCheckId) {\n    ...ClaimFactCheckForm_article\n    id\n  }\n}\n\nfragment ClaimFactCheckForm_team on Team {\n  id\n  get_language\n  ...ArticleForm_team\n}\n\nfragment ClaimFactCheckForm_article on FactCheck {\n  id\n  claim_description {\n    id\n  }\n  ...ArticleForm_article\n}\n\nfragment ArticleForm_article on Node {\n  ... on FactCheck {\n    title\n    summary\n    url\n    language\n    tags\n    rating\n    report_status\n    author {\n      name\n      id\n    }\n    updated_at\n    created_at\n    user {\n      name\n      id\n    }\n    claim_description {\n      description\n      context\n      project_media {\n        dbid\n        title\n        type\n        full_url\n        last_status_obj {\n          locked\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n  ... on Explainer {\n    title\n    description\n    url\n    language\n    tags\n    author {\n      name\n      id\n    }\n    updated_at\n    created_at\n    user {\n      name\n      id\n    }\n  }\n  ...ArticleTrash_article\n}\n\nfragment ArticleTrash_article on Node {\n  ... on FactCheck {\n    id\n    trashed\n    claim_description {\n      project_media {\n        title\n        full_url\n        type\n        id\n      }\n      id\n    }\n  }\n  ... on Explainer {\n    id\n    trashed\n  }\n}\n\nfragment ArticleForm_team on Team {\n  verification_statuses\n  get_language\n  get_languages\n  slug\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ClaimFactCheckFormQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ClaimFactCheckForm_team",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "fact_check",
        "storageKey": null,
        "args": v2,
        "concreteType": "FactCheck",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ClaimFactCheckForm_article",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ClaimFactCheckFormQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v3,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_language",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "verification_statuses",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_languages",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "fact_check",
        "storageKey": null,
        "args": v2,
        "concreteType": "FactCheck",
        "plural": false,
        "selections": [
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "claim_description",
            "storageKey": null,
            "args": null,
            "concreteType": "ClaimDescription",
            "plural": false,
            "selections": [
              v3,
              v4,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "context",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "project_media",
                "storageKey": null,
                "args": null,
                "concreteType": "ProjectMedia",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "dbid",
                    "args": null,
                    "storageKey": null
                  },
                  v5,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "type",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "full_url",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "last_status_obj",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Dynamic",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "locked",
                        "args": null,
                        "storageKey": null
                      },
                      v3
                    ]
                  },
                  v3
                ]
              }
            ]
          },
          v5,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "summary",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "url",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "language",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tags",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rating",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "report_status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "author",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": v6
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updated_at",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "created_at",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": v6
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "trashed",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "type": "Explainer",
            "selections": [
              v4
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1477990864e6f5115f903001d4e9428c';
module.exports = node;
