/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MediaCardLargeActions_projectMedia$ref = any;
type MediaOrigin_projectMedia$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaCardLargeFooter_projectMedia$ref: FragmentReference;
export type MediaCardLargeFooter_projectMedia = {|
  +media_slug: ?string,
  +title: ?string,
  +last_seen: ?string,
  +requests_count: ?number,
  +type: ?string,
  +media: ?{|
    +quote: ?string
  |},
  +extracted_text: ?{|
    +data: ?any
  |},
  +transcription: ?{|
    +data: ?any
  |},
  +$fragmentRefs: MediaCardLargeActions_projectMedia$ref & MediaOrigin_projectMedia$ref,
  +$refType: MediaCardLargeFooter_projectMedia$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "data",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "MediaCardLargeFooter_projectMedia",
  "type": "ProjectMedia",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "media_slug",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "last_seen",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "requests_count",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "type",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "media",
      "storageKey": null,
      "args": null,
      "concreteType": "Media",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "quote",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "extracted_text",
      "name": "annotation",
      "storageKey": "annotation(annotation_type:\"extracted_text\")",
      "args": [
        {
          "kind": "Literal",
          "name": "annotation_type",
          "value": "extracted_text",
          "type": "String!"
        }
      ],
      "concreteType": "Annotation",
      "plural": false,
      "selections": v0
    },
    {
      "kind": "LinkedField",
      "alias": "transcription",
      "name": "annotation",
      "storageKey": "annotation(annotation_type:\"transcription\")",
      "args": [
        {
          "kind": "Literal",
          "name": "annotation_type",
          "value": "transcription",
          "type": "String!"
        }
      ],
      "concreteType": "Annotation",
      "plural": false,
      "selections": v0
    },
    {
      "kind": "FragmentSpread",
      "name": "MediaCardLargeActions_projectMedia",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "MediaOrigin_projectMedia",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a5e6d58f7acdd3d42590dd30ead93aaa';
module.exports = node;
