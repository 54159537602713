/**
 * @flow
 * @relayHash 1c846fe0839abc5c1d54b655dc00ac88
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BotPreviewTiplineQueryVariables = {|
  teamSlug: string,
  enableLanguageDetection?: ?boolean,
  enableLinkShortening?: ?boolean,
  maxNumberOfWords?: ?number,
  searchText: string,
  shouldRestrictByLanguage?: ?boolean,
  threshold?: ?number,
  utmCode?: ?string,
|};
export type BotPreviewTiplineQueryResponse = {|
  +team: ?{|
    +bot_query: ?$ReadOnlyArray<{|
      +title: string,
      +body: ?string,
      +image_url: ?string,
      +url: ?string,
      +type: string,
    |}>
  |}
|};
export type BotPreviewTiplineQuery = {|
  variables: BotPreviewTiplineQueryVariables,
  response: BotPreviewTiplineQueryResponse,
|};
*/


/*
query BotPreviewTiplineQuery(
  $teamSlug: String!
  $enableLanguageDetection: Boolean
  $enableLinkShortening: Boolean
  $maxNumberOfWords: Int
  $searchText: String!
  $shouldRestrictByLanguage: Boolean
  $threshold: Float
  $utmCode: String
) {
  team(slug: $teamSlug) {
    bot_query(enableLanguageDetection: $enableLanguageDetection, enableLinkShortening: $enableLinkShortening, maxNumberOfWords: $maxNumberOfWords, searchText: $searchText, shouldRestrictByLanguage: $shouldRestrictByLanguage, threshold: $threshold, utmCode: $utmCode) {
      title
      body
      image_url
      url
      type
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "enableLanguageDetection",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "enableLinkShortening",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "maxNumberOfWords",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "searchText",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "shouldRestrictByLanguage",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "threshold",
    "type": "Float",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "utmCode",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "enableLanguageDetection",
    "variableName": "enableLanguageDetection",
    "type": "Boolean"
  },
  {
    "kind": "Variable",
    "name": "enableLinkShortening",
    "variableName": "enableLinkShortening",
    "type": "Boolean"
  },
  {
    "kind": "Variable",
    "name": "maxNumberOfWords",
    "variableName": "maxNumberOfWords",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "searchText",
    "variableName": "searchText",
    "type": "String!"
  },
  {
    "kind": "Variable",
    "name": "shouldRestrictByLanguage",
    "variableName": "shouldRestrictByLanguage",
    "type": "Boolean"
  },
  {
    "kind": "Variable",
    "name": "threshold",
    "variableName": "threshold",
    "type": "Float"
  },
  {
    "kind": "Variable",
    "name": "utmCode",
    "variableName": "utmCode",
    "type": "String"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "body",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "image_url",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "BotPreviewTiplineQuery",
  "id": null,
  "text": "query BotPreviewTiplineQuery(\n  $teamSlug: String!\n  $enableLanguageDetection: Boolean\n  $enableLinkShortening: Boolean\n  $maxNumberOfWords: Int\n  $searchText: String!\n  $shouldRestrictByLanguage: Boolean\n  $threshold: Float\n  $utmCode: String\n) {\n  team(slug: $teamSlug) {\n    bot_query(enableLanguageDetection: $enableLanguageDetection, enableLinkShortening: $enableLinkShortening, maxNumberOfWords: $maxNumberOfWords, searchText: $searchText, shouldRestrictByLanguage: $shouldRestrictByLanguage, threshold: $threshold, utmCode: $utmCode) {\n      title\n      body\n      image_url\n      url\n      type\n      id\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BotPreviewTiplineQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "bot_query",
            "storageKey": null,
            "args": v2,
            "concreteType": "TiplineSearchResult",
            "plural": true,
            "selections": [
              v3,
              v4,
              v5,
              v6,
              v7
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BotPreviewTiplineQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "bot_query",
            "storageKey": null,
            "args": v2,
            "concreteType": "TiplineSearchResult",
            "plural": true,
            "selections": [
              v3,
              v4,
              v5,
              v6,
              v7,
              v8
            ]
          },
          v8
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f0bee6e49ecf3a4258f6147e3ca22d55';
module.exports = node;
