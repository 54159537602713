/**
 * @flow
 * @relayHash 11a4de17dadabd5c7b75dc316be1cd01
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateUserInput = {
  id?: ?string,
  profile_image?: ?string,
  current_team_id?: ?number,
  email?: ?string,
  name?: ?string,
  current_project_id?: ?number,
  password?: ?string,
  password_confirmation?: ?string,
  send_email_notifications?: ?boolean,
  send_successful_login_notifications?: ?boolean,
  send_failed_login_notifications?: ?boolean,
  accept_terms?: ?boolean,
  completed_signup?: ?boolean,
  clientMutationId?: ?string,
};
export type UserTosComponentUpdateUserMutationVariables = {|
  input: UpdateUserInput
|};
export type UserTosComponentUpdateUserMutationResponse = {|
  +updateUser: ?{|
    +me: ?{|
      +accepted_terms: ?boolean
    |}
  |}
|};
export type UserTosComponentUpdateUserMutation = {|
  variables: UserTosComponentUpdateUserMutationVariables,
  response: UserTosComponentUpdateUserMutationResponse,
|};
*/


/*
mutation UserTosComponentUpdateUserMutation(
  $input: UpdateUserInput!
) {
  updateUser(input: $input) {
    me {
      accepted_terms
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateUserInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateUserInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "accepted_terms",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UserTosComponentUpdateUserMutation",
  "id": null,
  "text": "mutation UserTosComponentUpdateUserMutation(\n  $input: UpdateUserInput!\n) {\n  updateUser(input: $input) {\n    me {\n      accepted_terms\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UserTosComponentUpdateUserMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateUser",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateUserPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "Me",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserTosComponentUpdateUserMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateUser",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateUserPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "Me",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '89ddf682928c485eba754ec5863242b9';
module.exports = node;
