/**
 * @flow
 * @relayHash 29210bc605ef3171e5deea0627e89034
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateFactCheckInput = {
  id?: ?string,
  url?: ?string,
  language?: ?string,
  tags?: ?$ReadOnlyArray<?string>,
  rating?: ?string,
  title?: ?string,
  summary?: ?string,
  trashed?: ?boolean,
  clientMutationId?: ?string,
};
export type ArticlesUpdateFactCheckMutationVariables = {|
  input: UpdateFactCheckInput
|};
export type ArticlesUpdateFactCheckMutationResponse = {|
  +updateFactCheck: ?{|
    +fact_check: ?{|
      +id: string,
      +tags: ?$ReadOnlyArray<?string>,
    |}
  |}
|};
export type ArticlesUpdateFactCheckMutation = {|
  variables: ArticlesUpdateFactCheckMutationVariables,
  response: ArticlesUpdateFactCheckMutationResponse,
|};
*/


/*
mutation ArticlesUpdateFactCheckMutation(
  $input: UpdateFactCheckInput!
) {
  updateFactCheck(input: $input) {
    fact_check {
      id
      tags
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateFactCheckInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateFactCheck",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateFactCheckInput!"
      }
    ],
    "concreteType": "UpdateFactCheckPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "fact_check",
        "storageKey": null,
        "args": null,
        "concreteType": "FactCheck",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tags",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ArticlesUpdateFactCheckMutation",
  "id": null,
  "text": "mutation ArticlesUpdateFactCheckMutation(\n  $input: UpdateFactCheckInput!\n) {\n  updateFactCheck(input: $input) {\n    fact_check {\n      id\n      tags\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ArticlesUpdateFactCheckMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "ArticlesUpdateFactCheckMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2bbbc60f3ed238423eb5cc47c2967245';
module.exports = node;
