/**
 * @flow
 * @relayHash c75fdccf6e18ab658e5cea56549ba2f0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TeamReportComponent_team$ref = any;
export type TeamReportQueryVariables = {|
  teamSlug: string
|};
export type TeamReportQueryResponse = {|
  +team: ?{|
    +$fragmentRefs: TeamReportComponent_team$ref
  |}
|};
export type TeamReportQuery = {|
  variables: TeamReportQueryVariables,
  response: TeamReportQueryResponse,
|};
*/


/*
query TeamReportQuery(
  $teamSlug: String!
) {
  team(slug: $teamSlug) {
    ...TeamReportComponent_team
    id
  }
}

fragment TeamReportComponent_team on Team {
  id
  permissions
  get_language
  get_languages
  get_report
  smooch_bot: team_bot_installation(bot_identifier: "smooch") {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TeamReportQuery",
  "id": null,
  "text": "query TeamReportQuery(\n  $teamSlug: String!\n) {\n  team(slug: $teamSlug) {\n    ...TeamReportComponent_team\n    id\n  }\n}\n\nfragment TeamReportComponent_team on Team {\n  id\n  permissions\n  get_language\n  get_languages\n  get_report\n  smooch_bot: team_bot_installation(bot_identifier: \"smooch\") {\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TeamReportQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "TeamReportComponent_team",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TeamReportQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "permissions",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_language",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_languages",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_report",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": "smooch_bot",
            "name": "team_bot_installation",
            "storageKey": "team_bot_installation(bot_identifier:\"smooch\")",
            "args": [
              {
                "kind": "Literal",
                "name": "bot_identifier",
                "value": "smooch",
                "type": "String!"
              }
            ],
            "concreteType": "TeamBotInstallation",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c02c6d354d0c8a889b6ca3d6a7948c76';
module.exports = node;
