/**
 * @flow
 * @relayHash 57be1b4b5880f95c18ef1e74f1addc8f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ReportDesignerComponent_media$ref = any;
export type ReportDesignerQueryVariables = {|
  ids: string
|};
export type ReportDesignerQueryResponse = {|
  +project_media: ?{|
    +$fragmentRefs: ReportDesignerComponent_media$ref
  |}
|};
export type ReportDesignerQuery = {|
  variables: ReportDesignerQueryVariables,
  response: ReportDesignerQueryResponse,
|};
*/


/*
query ReportDesignerQuery(
  $ids: String!
) {
  project_media(ids: $ids) {
    ...ReportDesignerComponent_media
    id
  }
}

fragment ReportDesignerComponent_media on ProjectMedia {
  id
  dbid
  archived
  permissions
  last_status
  dynamic_annotation_report_design {
    id
    dbid
    data
  }
  team {
    slug
    get_language
    get_languages
    verification_statuses
    ...ReportDesignerForm_team
    id
  }
  ...ReportDesignerTopBar_media
  ...ReportDesignerPreview_media
  ...ReportDesignerForm_media
}

fragment ReportDesignerForm_team on Team {
  get_languages
  get_report
}

fragment ReportDesignerTopBar_media on ProjectMedia {
  dbid
  last_status
  dynamic_annotation_report_design {
    dbid
    data
    sent_count
    id
  }
  demand
  media {
    picture
    id
  }
  show_warning_cover
  team {
    verification_statuses
    get_languages
    alegre_bot: team_bot_installation(bot_identifier: "alegre") {
      alegre_settings
      id
    }
    id
  }
}

fragment ReportDesignerPreview_media on ProjectMedia {
  last_status
  show_warning_cover
  media {
    picture
    id
  }
  team {
    avatar
    get_report
    get_report_design_image_template
    verification_statuses
    id
  }
}

fragment ReportDesignerForm_media on ProjectMedia {
  media {
    picture
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ids",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ReportDesignerQuery",
  "id": null,
  "text": "query ReportDesignerQuery(\n  $ids: String!\n) {\n  project_media(ids: $ids) {\n    ...ReportDesignerComponent_media\n    id\n  }\n}\n\nfragment ReportDesignerComponent_media on ProjectMedia {\n  id\n  dbid\n  archived\n  permissions\n  last_status\n  dynamic_annotation_report_design {\n    id\n    dbid\n    data\n  }\n  team {\n    slug\n    get_language\n    get_languages\n    verification_statuses\n    ...ReportDesignerForm_team\n    id\n  }\n  ...ReportDesignerTopBar_media\n  ...ReportDesignerPreview_media\n  ...ReportDesignerForm_media\n}\n\nfragment ReportDesignerForm_team on Team {\n  get_languages\n  get_report\n}\n\nfragment ReportDesignerTopBar_media on ProjectMedia {\n  dbid\n  last_status\n  dynamic_annotation_report_design {\n    dbid\n    data\n    sent_count\n    id\n  }\n  demand\n  media {\n    picture\n    id\n  }\n  show_warning_cover\n  team {\n    verification_statuses\n    get_languages\n    alegre_bot: team_bot_installation(bot_identifier: \"alegre\") {\n      alegre_settings\n      id\n    }\n    id\n  }\n}\n\nfragment ReportDesignerPreview_media on ProjectMedia {\n  last_status\n  show_warning_cover\n  media {\n    picture\n    id\n  }\n  team {\n    avatar\n    get_report\n    get_report_design_image_template\n    verification_statuses\n    id\n  }\n}\n\nfragment ReportDesignerForm_media on ProjectMedia {\n  media {\n    picture\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ReportDesignerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project_media",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProjectMedia",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ReportDesignerComponent_media",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ReportDesignerQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "project_media",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProjectMedia",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "archived",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "permissions",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "last_status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "dynamic_annotation_report_design",
            "storageKey": null,
            "args": null,
            "concreteType": "Dynamic",
            "plural": false,
            "selections": [
              v2,
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "data",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "sent_count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "slug",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "get_language",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "get_languages",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "verification_statuses",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "get_report",
                "args": null,
                "storageKey": null
              },
              v2,
              {
                "kind": "LinkedField",
                "alias": "alegre_bot",
                "name": "team_bot_installation",
                "storageKey": "team_bot_installation(bot_identifier:\"alegre\")",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "bot_identifier",
                    "value": "alegre",
                    "type": "String!"
                  }
                ],
                "concreteType": "TeamBotInstallation",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "alegre_settings",
                    "args": null,
                    "storageKey": null
                  },
                  v2
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "avatar",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "get_report_design_image_template",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "demand",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "media",
            "storageKey": null,
            "args": null,
            "concreteType": "Media",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "picture",
                "args": null,
                "storageKey": null
              },
              v2
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "show_warning_cover",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '628894e9e66b52d7f4a3c4b8620ec66c';
module.exports = node;
