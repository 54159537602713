/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type EditStatusDialog_team$ref: FragmentReference;
export type EditStatusDialog_team = {|
  +smooch_bot: ?{|
    +id: string
  |},
  +$refType: EditStatusDialog_team$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "EditStatusDialog_team",
  "type": "Team",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "smooch_bot",
      "name": "team_bot_installation",
      "storageKey": "team_bot_installation(bot_identifier:\"smooch\")",
      "args": [
        {
          "kind": "Literal",
          "name": "bot_identifier",
          "value": "smooch",
          "type": "String!"
        }
      ],
      "concreteType": "TeamBotInstallation",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'fcf43474b28e951f90713c1814c4053e';
module.exports = node;
