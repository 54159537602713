/**
 * @flow
 * @relayHash 83c6f7839a9c99a38081db971c2ea812
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PaginatedTeamTags_root$ref = any;
export type TeamTagsQueryVariables = {|
  teamSlug: string,
  pageSize: number,
  after?: ?string,
  keyword?: ?string,
|};
export type TeamTagsQueryResponse = {|
  +team: ?{|
    +id: string,
    +dbid: ?number,
    +permissions: ?string,
    +get_rules: ?any,
    +rules_json_schema: ?string,
    +$fragmentRefs: PaginatedTeamTags_root$ref,
  |}
|};
export type TeamTagsQuery = {|
  variables: TeamTagsQueryVariables,
  response: TeamTagsQueryResponse,
|};
*/


/*
query TeamTagsQuery(
  $teamSlug: String!
  $pageSize: Int!
  $after: String
  $keyword: String
) {
  team(slug: $teamSlug) {
    id
    dbid
    permissions
    get_rules
    rules_json_schema
    ...PaginatedTeamTags_root
  }
}

fragment PaginatedTeamTags_root on Team {
  tag_texts_count
  tag_texts(first: $pageSize, after: $after, keyword: $keyword) {
    edges {
      node {
        id
        text
        updated_at
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "pageSize",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "keyword",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permissions",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "get_rules",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rules_json_schema",
  "args": null,
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "keyword",
    "variableName": "keyword",
    "type": "String"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TeamTagsQuery",
  "id": null,
  "text": "query TeamTagsQuery(\n  $teamSlug: String!\n  $pageSize: Int!\n  $after: String\n  $keyword: String\n) {\n  team(slug: $teamSlug) {\n    id\n    dbid\n    permissions\n    get_rules\n    rules_json_schema\n    ...PaginatedTeamTags_root\n  }\n}\n\nfragment PaginatedTeamTags_root on Team {\n  tag_texts_count\n  tag_texts(first: $pageSize, after: $after, keyword: $keyword) {\n    edges {\n      node {\n        id\n        text\n        updated_at\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TeamTagsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          {
            "kind": "FragmentSpread",
            "name": "PaginatedTeamTags_root",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TeamTagsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tag_texts_count",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tag_texts",
            "storageKey": null,
            "args": v7,
            "concreteType": "TagTextConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TagTextEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TagText",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "text",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "updated_at",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "tag_texts",
            "args": v7,
            "handle": "connection",
            "key": "PaginatedTeamTags_tag_texts",
            "filters": [
              "keyword"
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dd3697b672b8762ca04a86c73f2202f8';
module.exports = node;
