/**
 * @flow
 * @relayHash d36f58778c4cc518ed35009395764467
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RulesComponent_team$ref = any;
export type RulesQueryVariables = {|
  teamSlug: string
|};
export type RulesQueryResponse = {|
  +team: ?{|
    +$fragmentRefs: RulesComponent_team$ref
  |}
|};
export type RulesQuery = {|
  variables: RulesQueryVariables,
  response: RulesQueryResponse,
|};
*/


/*
query RulesQuery(
  $teamSlug: String!
) {
  team(slug: $teamSlug) {
    ...RulesComponent_team
    id
  }
}

fragment RulesComponent_team on Team {
  id
  get_rules
  rules_json_schema
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "RulesQuery",
  "id": null,
  "text": "query RulesQuery(\n  $teamSlug: String!\n) {\n  team(slug: $teamSlug) {\n    ...RulesComponent_team\n    id\n  }\n}\n\nfragment RulesComponent_team on Team {\n  id\n  get_rules\n  rules_json_schema\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RulesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "RulesComponent_team",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RulesQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_rules",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rules_json_schema",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '408e3577c55d828408ef629e875a4e54';
module.exports = node;
