/**
 * @flow
 * @relayHash 76c947e7fef0e020b95a3c93aaaedf41
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DrawerRail_team$ref = any;
export type DrawerNavigationQueryVariables = {|
  teamSlug: string
|};
export type DrawerNavigationQueryResponse = {|
  +find_public_team: ?{|
    +dbid: ?number,
    +$fragmentRefs: DrawerRail_team$ref,
  |}
|};
export type DrawerNavigationQuery = {|
  variables: DrawerNavigationQueryVariables,
  response: DrawerNavigationQueryResponse,
|};
*/


/*
query DrawerNavigationQuery(
  $teamSlug: String!
) {
  find_public_team(slug: $teamSlug) {
    dbid
    ...DrawerRail_team
    id
  }
}

fragment DrawerRail_team on PublicTeam {
  slug
  private
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DrawerNavigationQuery",
  "id": null,
  "text": "query DrawerNavigationQuery(\n  $teamSlug: String!\n) {\n  find_public_team(slug: $teamSlug) {\n    dbid\n    ...DrawerRail_team\n    id\n  }\n}\n\nfragment DrawerRail_team on PublicTeam {\n  slug\n  private\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DrawerNavigationQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "find_public_team",
        "storageKey": null,
        "args": v1,
        "concreteType": "PublicTeam",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "FragmentSpread",
            "name": "DrawerRail_team",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DrawerNavigationQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "find_public_team",
        "storageKey": null,
        "args": v1,
        "concreteType": "PublicTeam",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "private",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "avatar",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '835ffd96140d52731cbc48b912978894';
module.exports = node;
