/**
 * @flow
 * @relayHash 015a32ef8c7e4f92529954b5a398a94e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTeamBotInstallationInput = {
  id?: ?string,
  json_settings?: ?string,
  lock_version?: ?number,
  clientMutationId?: ?string,
};
export type BotPreviewUpdateTeamBotInstallationMutationVariables = {|
  input: UpdateTeamBotInstallationInput
|};
export type BotPreviewUpdateTeamBotInstallationMutationResponse = {|
  +updateTeamBotInstallation: ?{|
    +team_bot_installation: ?{|
      +id: string,
      +json_settings: ?string,
      +lock_version: ?number,
    |}
  |}
|};
export type BotPreviewUpdateTeamBotInstallationMutation = {|
  variables: BotPreviewUpdateTeamBotInstallationMutationVariables,
  response: BotPreviewUpdateTeamBotInstallationMutationResponse,
|};
*/


/*
mutation BotPreviewUpdateTeamBotInstallationMutation(
  $input: UpdateTeamBotInstallationInput!
) {
  updateTeamBotInstallation(input: $input) {
    team_bot_installation {
      id
      json_settings
      lock_version
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTeamBotInstallationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateTeamBotInstallation",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateTeamBotInstallationInput!"
      }
    ],
    "concreteType": "UpdateTeamBotInstallationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team_bot_installation",
        "storageKey": null,
        "args": null,
        "concreteType": "TeamBotInstallation",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "json_settings",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lock_version",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "BotPreviewUpdateTeamBotInstallationMutation",
  "id": null,
  "text": "mutation BotPreviewUpdateTeamBotInstallationMutation(\n  $input: UpdateTeamBotInstallationInput!\n) {\n  updateTeamBotInstallation(input: $input) {\n    team_bot_installation {\n      id\n      json_settings\n      lock_version\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BotPreviewUpdateTeamBotInstallationMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "BotPreviewUpdateTeamBotInstallationMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '683c18d9e7cca9e8919c82f1b5adc94a';
module.exports = node;
