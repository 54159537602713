/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type UserPrivacy_user$ref = any;
type UserProfile_user$ref = any;
type UserSecurity_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MeComponent_user$ref: FragmentReference;
export type MeComponent_user = {|
  +name: ?string,
  +$fragmentRefs: UserProfile_user$ref & UserSecurity_user$ref & UserPrivacy_user$ref,
  +$refType: MeComponent_user$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "MeComponent_user",
  "type": "Me",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "UserProfile_user",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "UserSecurity_user",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "UserPrivacy_user",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '10c7a85a9309cbe60d76dce8a98cd9b9';
module.exports = node;
