/**
 * @flow
 * @relayHash 554a91d2e3a348059c8ce7a5244d527e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserTosComponent_about$ref = any;
export type UserTosQueryVariables = {||};
export type UserTosQueryResponse = {|
  +about: ?{|
    +$fragmentRefs: UserTosComponent_about$ref
  |}
|};
export type UserTosQuery = {|
  variables: UserTosQueryVariables,
  response: UserTosQueryResponse,
|};
*/


/*
query UserTosQuery {
  about {
    ...UserTosComponent_about
    id
  }
}

fragment UserTosComponent_about on About {
  terms_last_updated_at
}
*/

const node/*: ConcreteRequest*/ = {
  "kind": "Request",
  "operationKind": "query",
  "name": "UserTosQuery",
  "id": null,
  "text": "query UserTosQuery {\n  about {\n    ...UserTosComponent_about\n    id\n  }\n}\n\nfragment UserTosComponent_about on About {\n  terms_last_updated_at\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UserTosQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "about",
        "storageKey": null,
        "args": null,
        "concreteType": "About",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "UserTosComponent_about",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserTosQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "about",
        "storageKey": null,
        "args": null,
        "concreteType": "About",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "terms_last_updated_at",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
// prettier-ignore
(node/*: any*/).hash = '4cda635bf75fda138c9c643311369f24';
module.exports = node;
