/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ReportDesignerTopBar_media$ref: FragmentReference;
export type ReportDesignerTopBar_media = {|
  +dbid: ?number,
  +last_status: ?string,
  +dynamic_annotation_report_design: ?{|
    +dbid: ?string,
    +data: ?any,
    +sent_count: ?number,
  |},
  +demand: ?number,
  +media: ?{|
    +picture: ?string
  |},
  +show_warning_cover: ?boolean,
  +team: ?{|
    +verification_statuses: ?any,
    +get_languages: ?string,
    +alegre_bot: ?{|
      +alegre_settings: ?any
    |},
  |},
  +$refType: ReportDesignerTopBar_media$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ReportDesignerTopBar_media",
  "type": "ProjectMedia",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "last_status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "dynamic_annotation_report_design",
      "storageKey": null,
      "args": null,
      "concreteType": "Dynamic",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "data",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "sent_count",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "demand",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "media",
      "storageKey": null,
      "args": null,
      "concreteType": "Media",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "picture",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "show_warning_cover",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "team",
      "storageKey": null,
      "args": null,
      "concreteType": "Team",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "verification_statuses",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "get_languages",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": "alegre_bot",
          "name": "team_bot_installation",
          "storageKey": "team_bot_installation(bot_identifier:\"alegre\")",
          "args": [
            {
              "kind": "Literal",
              "name": "bot_identifier",
              "value": "alegre",
              "type": "String!"
            }
          ],
          "concreteType": "TeamBotInstallation",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "alegre_settings",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b86f72df1f7fe557b2f312b6cd41831a';
module.exports = node;
