/**
 * @flow
 * @relayHash 7421cc525523970205f754cdb245aea6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTeamBotInstallationInput = {
  id?: ?string,
  json_settings?: ?string,
  lock_version?: ?number,
  clientMutationId?: ?string,
};
export type BotPreviewToggleSendArticlesInSameLanguageMutationVariables = {|
  input: UpdateTeamBotInstallationInput
|};
export type BotPreviewToggleSendArticlesInSameLanguageMutationResponse = {|
  +updateTeamBotInstallation: ?{|
    +team_bot_installation: ?{|
      +team: ?{|
        +alegre_bot: ?{|
          +id: string,
          +alegre_settings: ?any,
          +lock_version: ?number,
        |}
      |}
    |}
  |}
|};
export type BotPreviewToggleSendArticlesInSameLanguageMutation = {|
  variables: BotPreviewToggleSendArticlesInSameLanguageMutationVariables,
  response: BotPreviewToggleSendArticlesInSameLanguageMutationResponse,
|};
*/


/*
mutation BotPreviewToggleSendArticlesInSameLanguageMutation(
  $input: UpdateTeamBotInstallationInput!
) {
  updateTeamBotInstallation(input: $input) {
    team_bot_installation {
      team {
        alegre_bot: team_bot_installation(bot_identifier: "alegre") {
          id
          alegre_settings
          lock_version
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTeamBotInstallationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateTeamBotInstallationInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": "alegre_bot",
  "name": "team_bot_installation",
  "storageKey": "team_bot_installation(bot_identifier:\"alegre\")",
  "args": [
    {
      "kind": "Literal",
      "name": "bot_identifier",
      "value": "alegre",
      "type": "String!"
    }
  ],
  "concreteType": "TeamBotInstallation",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "alegre_settings",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lock_version",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "BotPreviewToggleSendArticlesInSameLanguageMutation",
  "id": null,
  "text": "mutation BotPreviewToggleSendArticlesInSameLanguageMutation(\n  $input: UpdateTeamBotInstallationInput!\n) {\n  updateTeamBotInstallation(input: $input) {\n    team_bot_installation {\n      team {\n        alegre_bot: team_bot_installation(bot_identifier: \"alegre\") {\n          id\n          alegre_settings\n          lock_version\n        }\n        id\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BotPreviewToggleSendArticlesInSameLanguageMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateTeamBotInstallation",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateTeamBotInstallationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team_bot_installation",
            "storageKey": null,
            "args": null,
            "concreteType": "TeamBotInstallation",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "Team",
                "plural": false,
                "selections": [
                  v3
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BotPreviewToggleSendArticlesInSameLanguageMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateTeamBotInstallation",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateTeamBotInstallationPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team_bot_installation",
            "storageKey": null,
            "args": null,
            "concreteType": "TeamBotInstallation",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "Team",
                "plural": false,
                "selections": [
                  v3,
                  v2
                ]
              },
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '32fe3ef2bc452d8e1434a8ab34ac810a';
module.exports = node;
