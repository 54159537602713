/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MediaOriginBanner_projectMedia$ref: FragmentReference;
export type MediaOriginBanner_projectMedia = {|
  +media_cluster_origin: ?number,
  +media_cluster_origin_user: ?{|
    +name: ?string
  |},
  +media_cluster_origin_timestamp: ?number,
  +media_cluster_relationship: ?{|
    +target: ?{|
      +title: ?string
    |},
    +confirmed_by: ?{|
      +name: ?string
    |},
  |},
  +$refType: MediaOriginBanner_projectMedia$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "MediaOriginBanner_projectMedia",
  "type": "ProjectMedia",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "media_cluster_origin",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "media_cluster_origin_user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": v0
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "media_cluster_origin_timestamp",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "media_cluster_relationship",
      "storageKey": null,
      "args": null,
      "concreteType": "Relationship",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "target",
          "storageKey": null,
          "args": null,
          "concreteType": "ProjectMedia",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "title",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "confirmed_by",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": v0
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '52362b46c9c6c699057f1ececb6ebf54';
module.exports = node;
